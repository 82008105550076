import React from 'react';
import close from 'assets/icons/close.svg';
import './Modal.scss';

interface ModalProps {
  onClose?: () => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({
  onClose,
  children,
}): JSX.Element => {
  return (
    <div className="modal" onClick={onClose}>
      <div className="modal__container" onClick={(e) => e.stopPropagation()}>
        <div className="modal__card">
          {children}
          {onClose && (
            <img className="modal__close" src={close} onClick={onClose} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
