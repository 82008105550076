import React from 'react';
import './DeviceSmallItem.scss';
import Device from 'models/Device';
import lockIcon from 'assets/icons/lock.svg';
import { Str } from 'i18n';
import clsx from 'clsx';

const DeviceSmallItem: React.FunctionComponent<Device> = ({
  deviceId,
  locked,
}): JSX.Element => {
  const isCollar = deviceId.toString().length < 5;

  return (
    <div
      className={clsx('deviceSmallItem', locked && 'deviceSmallItem__withIcon')}
    >
      {locked && (
        <img className="deviceSmallItem__icon" src={lockIcon} alt="Lock" />
      )}
      <b>{deviceId}</b>
      &nbsp; <Str id={isCollar ? 'collar' : 'connector'} />
    </div>
  );
};

export default DeviceSmallItem;
