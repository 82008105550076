import React from 'react';
import './DeviceListItem.scss';
import Device from 'models/Device';
import lockIcon from 'assets/icons/lock.svg';
import { Str } from 'i18n';
import clsx from 'clsx';

interface DeviceListItemProps extends Device {
  selected?: boolean;
  onClick?: () => void;
}

const DeviceListItem: React.FunctionComponent<DeviceListItemProps> = ({
  deviceId,
  selected,
  locked,
  onClick,
}): JSX.Element => {
  const isCollar = deviceId.toString().length < 5;

  return (
    <div
      className={clsx('deviceListItem', selected && 'deviceListItem__selected')}
      onClick={onClick}
    >
      {locked && (
        <img className="deviceListItem__icon" src={lockIcon} alt="Lock" />
      )}
      <b>
        <Str id={isCollar ? 'collar' : 'connector'} />
      </b>
      <p>{deviceId}</p>
    </div>
  );
};

export default DeviceListItem;
