import React from 'react';
import './UserDetails.scss';
import Button from 'components/atoms/Button';
import UserInfo from 'models/UserInfo';
import UserImage from 'components/atoms/UserImage';
import clsx from 'clsx';
import { UserComponent } from 'auth';

interface UserDetailsProps extends UserInfo {
  onLogout?: () => void;
  small?: boolean;
}

const UserDetails: React.FunctionComponent<UserDetailsProps> = ({
  nome,
  immagine,
  onLogout,
  small = false,
  uid,
}): JSX.Element => {
  return (
    <div className={clsx('userDetails', small && 'userDetails__small')}>
      <div className="userDetails__header">
        <UserImage src={immagine} small={small} />
        <div className="userDetails__displayName">
          {!small && <h3>{nome}</h3>}
          {small && <p>{nome}</p>}
          <UserComponent admin>
            <small>{uid}</small>
          </UserComponent>
        </div>
        {onLogout && <Button flat labelId="logout" onClick={onLogout} />}
      </div>
    </div>
  );
};

export default UserDetails;
