import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import firebaseConfig from './firebaseConfig.json';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';
import { LangRoute } from 'i18n';
import Loading from 'components/organisms/Loading';
import firebase from 'firebase';

// if (process.env.REACT_APP_FIREBASE_DATABASE_URL) {
//   firebaseConfig.databaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
// }

const firebaseApp = firebase.initializeApp(firebaseConfig);

// if (process.env.REACT_APP_FIREBASE_FIRESTORE_CONFIG) {
//   firebaseApp
//     .firestore()
//     .settings(JSON.parse(process.env.REACT_APP_FIREBASE_FIRESTORE_CONFIG));
// }

// if (process.env.REACT_APP_FIREBASE_FUNCTIONS_URL) {
//   firebaseApp
//     .functions()
//     .useFunctionsEmulator(process.env.REACT_APP_FIREBASE_FUNCTIONS_URL);
// }

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <FirebaseAppProvider firebaseApp={firebaseApp}>
        <SuspenseWithPerf
          fallback={<Loading />}
          traceId={'load-burrito-status'}
        >
          <LangRoute>
            <App />
          </LangRoute>
        </SuspenseWithPerf>
      </FirebaseAppProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
