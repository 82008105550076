import locales, { DEFAULT_LANG } from './locales';

export default function translate(
  lang: keyof typeof locales,
  key: string,
  values?: Record<string, string>,
  def?: string
): string {
  let val = locales[lang][key] || locales[DEFAULT_LANG][key] || def || '';
  values &&
    Object.keys(values).forEach((key) => {
      val = val.split(`{${key}}`).join(values[key]);
    });
  if (!val || !(val as string)) {
    throw new Error(`Invalid key ${key} for language ${lang}`);
  }
  return val;
}
