import React from 'react';
import benelli from 'assets/images/logo-benelli-red.png';
import caddy from 'assets/images/logo-caddy-red.png';
import './Header.scss';

export interface HeaderProps {
  action: React.ReactNode;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  action,
}): JSX.Element => (
  <div className="header">
    <img
      alt="Benelli logo"
      src={benelli}
      className="header__logo-link"
      onClick={() => (window.location.href = 'https://www.benelli.it/it/')}
    />
    <img src={caddy} className="header__logo" alt="Caddy logo" />
    {action}
  </div>
);

export default Header;
