import React, { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import Modal from 'components/layouts/Modal';
import { Str, useTranslate } from 'i18n';
import Input from 'components/atoms/Input';
import './CopySessionModal.scss';
import { State } from 'hooks/useOwnerTransfer';
import Loader from 'components/atoms/Loader';
import { useCopySession } from 'hooks/useCopySession';
import { useAuth } from 'reactfire';

interface CopySessionModalProps {
  sessionId: string;
  uid: string;
  onDone: () => void;
}

const CopySessionModal: React.FunctionComponent<CopySessionModalProps> = ({
  sessionId,
  uid,
  onDone,
}): JSX.Element => {
  const { t } = useTranslate();
  const { copy, state } = useCopySession(uid, sessionId);
  const [toUid, setToUid] = useState('');
  const currentUid = useAuth().currentUser?.uid || '';

  useEffect(() => {
    if (state === State.SUCCESS) {
      onDone();
      alert(t('copy_session_success'));
    } else if (state === State.ERROR) {
      alert(t('copy_session_error'));
    }
  }, [state, onDone]);

  return (
    <Modal onClose={(state !== State.LOADING && onDone) || undefined}>
      {state === State.LOADING ? (
        <Loader size="medium" />
      ) : (
        <form
          onSubmit={(e) => {
            window.confirm(t('copy_session_confirm_message')) &&
              copy(toUid || currentUid);
            e.preventDefault();
          }}
          className="copySessionModal"
        >
          <p>
            <b>
              <Str id="copy_session" />
            </b>
          </p>
          <br />
          <span>
            <Str id="recipient_user" />
          </span>
          <Input
            value={toUid}
            onChange={(uid) => setToUid(uid)}
            hint={currentUid}
          />
          <div className="copySessionModal__space" />
          <div className="copySessionModal__actions">
            <Button labelId="cancel" secondary flat onClick={onDone} />
            <Button labelId="copy" type="submit" />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default CopySessionModal;
