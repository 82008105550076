import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import Login from 'components/pages/Login';
import Home from 'components/pages/Home';
import AppLayout from 'components/layouts/AppLayout';
import UsersResearch from 'components/pages/UsersResearch';
import LangSelection from 'components/molecules/LangSelection';
import PushNotification from 'components/pages/PushNotification';
import Loading from 'components/organisms/Loading';
import { UserComponent } from 'auth';
import UserProfile from 'components/organisms/UserProfile';
import { useAuth } from 'reactfire';

const App: React.FunctionComponent = (): JSX.Element => {
  const { path, url } = useRouteMatch();
  const { replace } = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const customToken = query.get('customToken');
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const customSignin = useCallback(
    async (token: string) => {
      query.delete('customToken');
      replace(`${url}?${query.toString()}`);
      setLoading(true);
      try {
        await auth.signInWithCustomToken(token);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    },
    [setLoading, query, replace]
  );

  useEffect(() => {
    customToken && customSignin(customToken);
  }, [customToken]);

  return loading ? (
    <Loading />
  ) : (
    <AppLayout
      action={
        <UserComponent fallback={<LangSelection />}>
          <UserProfile />
        </UserComponent>
      }
    >
      <Switch>
        <Route path={`${path}/login`} component={Login} />
        <Route path={`${path}/home`} component={Home} />
        <Route path={`${path}/users-research`} component={UsersResearch} />
        <Route
          path={`${path}/push-notification`}
          component={PushNotification}
        />
        <Route
          path="/*"
          render={() => <Redirect to={`${url}/home?${query.toString()}`} />}
        />
      </Switch>
    </AppLayout>
  );
};

export default App;
