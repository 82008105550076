import * as React from 'react';
import useTranslate from '../useTranslate';

interface Props {
  id: string;
  def?: string;
}

const Str: React.FunctionComponent<Props> = ({ id, def }): JSX.Element => {
  const { t } = useTranslate();
  return <>{t(id, undefined, def)}</>;
};

export default Str;
