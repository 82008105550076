import React, { useState } from 'react';
import './DeviceDetails.scss';
import Map from 'components/atoms/Map';
import { Str } from 'i18n';
import UserDetails from 'components/molecules/UserDetails';
import useDevice from 'hooks/useDevice';
import Button from 'components/atoms/Button';
import lockIcon from 'assets/icons/lock.svg';
import Loader from 'components/atoms/Loader';
import TransferOwnershipModal from '../TransferOwnershipModal';

interface DeviceDetailsProps {
  deviceId: string;
  onInvalidId: () => void;
}

const DeviceDetails: React.FunctionComponent<DeviceDetailsProps> = ({
  deviceId,
  onInvalidId,
}): JSX.Element => {
  const {
    device,
    isCollar,
    position,
    isMine,
    owner,
    lockLoading,
    lockToggle,
    isVisible,
  } = useDevice(deviceId);

  const [showOwnerTrasfer, setShowOwnerTrasfer] = useState(false);

  if (!isVisible) {
    onInvalidId();
    return <noscript />;
  }

  return (
    <>
      <div className="deviceDetails">
        <div className="deviceDetails__padding">
          <div className="deviceDetails__horizontal">
            {device.locked && (
              <img className="deviceDetails__icon" src={lockIcon} alt="Lock" />
            )}
            <h3>
              <Str id={isCollar ? 'collar' : 'connector'} /> {device.deviceId}
            </h3>
          </div>
          <span>
            {position && (
              <>
                <Str id="last_saved_position" />
                {`  -  `}
                {new Date(position.timestamp).toLocaleString()}
              </>
            )}
            {!position && <Str id="no_saved_positions" />}
          </span>
        </div>
        {position && (
          <div className="deviceDetails__map">
            <Map marker={position} />
          </div>
        )}
        {!lockLoading && (
          <div className="deviceDetails__padding deviceDetails__centeredList">
            <p>
              <Str id={device.locked ? 'locked_message' : 'unlocked_message'} />
              {isMine && (
                <>
                  <br />
                  <Str
                    id={
                      device.locked
                        ? 'locked_message_action'
                        : 'unlocked_message_action'
                    }
                  />
                </>
              )}
            </p>
            {isMine && (
              <>
                <p>
                  <Button
                    labelId={device.locked ? 'unlock' : 'lock'}
                    onClick={lockToggle}
                  />
                </p>
                <Button
                  flat
                  secondary
                  labelId="owner_trasfer"
                  onClick={() => setShowOwnerTrasfer(true)}
                />
              </>
            )}
          </div>
        )}
        {lockLoading && (
          <div className="deviceDetails__padding deviceDetails__centeredList">
            <Loader size="small" />
          </div>
        )}
        {!isMine && (
          <div className="deviceDetails__padding">
            <h3>
              <Str id="owner" />
            </h3>
            <UserDetails {...owner} small />
          </div>
        )}
      </div>
      {showOwnerTrasfer && (
        <TransferOwnershipModal
          deviceId={deviceId}
          onDone={() => setShowOwnerTrasfer(false)}
        />
      )}
    </>
  );
};

export default DeviceDetails;
