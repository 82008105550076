import { useFunctions } from 'reactfire';
import { useCallback, useState } from 'react';

export enum State {
  LOADING,
  SUCCESS,
  ERROR,
}

interface CopySession {
  copy: (toUid: string) => void;
  state?: State;
}

export const useCopySession = (uid: string, sessionId: string): CopySession => {
  const functions = useFunctions();
  const [state, setState] = useState<State | undefined>();

  const copy = useCallback(
    async (toUid) => {
      if (state === State.LOADING) return;
      const sessionCopy = functions.httpsCallable('sessionCopy');
      setState(State.LOADING);
      try {
        await sessionCopy({
          toUid,
          sessionId,
          fromUid: uid,
        });
        setState(State.SUCCESS);
      } catch (e) {
        console.error(e);
        setState(State.ERROR);
      }
    },
    [functions, state, setState]
  );

  return {
    copy,
    state,
  };
};
