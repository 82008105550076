import React from 'react';
import './Loading.scss';
import logo from 'assets/images/logo-caddy-red.png';
import Loader from 'components/atoms/Loader';

const Loading: React.FunctionComponent = (): JSX.Element => {
  return (
    <div className="loading">
      <img src={logo} className="loading__logo" alt="Caddy logo" />
      <Loader size="large" />
    </div>
  );
};

export default Loading;
