import React from 'react';
import clsx from 'clsx';
import './Loader.scss';

interface Sizes {
  small: string;
  medium: string;
  large: string;
}

interface LoaderProps {
  size?: keyof Sizes;
}

const Loader: React.FunctionComponent<LoaderProps> = ({
  size = 'medium',
}): JSX.Element => {
  return (
    <div className={clsx('ldsRing', `ldsRing__${size}`)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
