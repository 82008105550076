import { withAdmin } from 'auth';
import Button from 'components/atoms/Button';
import SplittedLayout from 'components/layouts/SplittedLayout';
import PushListItem from 'components/molecules/PushListItem';
import PushDetails from 'components/organisms/PushDetails';
import SendPushModal from 'components/organisms/SendPushModal';
import Push, { PushForm } from 'models/Push';
import React, { useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import './PushNotification.scss';

const PushNotification: React.FunctionComponent = (): JSX.Element => {
  const pushRef = useFirestore().collection(`push`);
  const pushQuery = pushRef.orderBy('created_at', 'desc');
  const pushes = useFirestoreCollectionData<Push>(pushQuery, { idField: 'id' });
  const [selectedPush, setSelectedPush] = useState<Push>(pushes[0]);
  const [showSendModal, setShowSendModal] = useState(false);
  const [initialValues, setInitialValues] = useState<PushForm>();

  return (
    <>
      <SplittedLayout
        left={
          <>
            <div className="newPushNotification">
              <Button
                full
                labelId={'new_push'}
                onClick={() => setShowSendModal(true)}
              />
            </div>
            {pushes.map((push) => {
              return (
                <PushListItem
                  selected={push === selectedPush}
                  onClick={() => setSelectedPush(push)}
                  {...push}
                  key={push.id}
                />
              );
            })}
          </>
        }
        right={
          selectedPush && (
            <PushDetails
              {...selectedPush}
              onSendAgain={() => {
                setInitialValues(selectedPush);
                setShowSendModal(true);
              }}
            />
          )
        }
      />
      {showSendModal && (
        <SendPushModal
          initialValues={initialValues}
          onDone={async (result) => {
            if (result) {
              try {
                await pushRef.add(result);
                setInitialValues(undefined);
              } catch (e) {
                alert('si è verificato un errore, riprovare');
                return;
              }
            }
            setShowSendModal(false);
          }}
        />
      )}
    </>
  );
};

export default withAdmin(PushNotification);
