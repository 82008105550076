import React from 'react';
import { Str } from 'i18n';
import clsx from 'clsx';
import './SocialButton.scss';

interface ButtonProps {
  label?: string;
  labelId?: string;
  full?: boolean;
  color: string;
  icon: string;
  onClick?: () => void;
}

const SocialButton: React.FunctionComponent<ButtonProps> = ({
  label,
  labelId,
  full,
  color,
  icon,
  onClick,
}): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={clsx('socialButton', full && 'socialButton__full')}
      style={{ backgroundColor: color }}
    >
      {label || (labelId && <Str id={labelId} />)}
      <img src={icon} className="socialButton__icon" alt="Social logo" />
    </button>
  );
};

export default SocialButton;
