import React from 'react';
import { useTranslate } from 'i18n';
import './Input.scss';

interface InputProps {
  hint?: string;
  hintId?: string;
  value: string;
  onChange: (value: string) => void;
  type?: string;
  textArea?: boolean;
}

const Input: React.FunctionComponent<InputProps> = ({
  hint,
  hintId,
  value,
  onChange,
  type = 'text',
  textArea,
}): JSX.Element => {
  const { t } = useTranslate();

  const props = {
    type,
    value,
    className: textArea ? 'input input__area' : 'input',
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      onChange(e.target.value),
    placeholder: hint || t(hintId),
  };
  return textArea ? <textarea {...props} /> : <input {...props} />;
};

export default Input;
