import it from './it.json';
import en from './en.json';

const ALL = {
  en: en as Record<string, string>,
  it: it as Record<string, string>,
};

export const LANGS = Object.keys(ALL);
export const DEFAULT_LANG = 'it';

export default ALL;
