import React from 'react';
import { Str } from 'i18n';
import clsx from 'clsx';
import './Button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  labelId?: string;
  secondary?: boolean;
  full?: boolean;
  disabled?: boolean;
  flat?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  label,
  labelId,
  secondary,
  full,
  disabled,
  flat,
  type,
  onClick,
  className,
}): JSX.Element => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        'button',
        secondary && 'button__secondary',
        full && 'button__full',
        disabled && 'button__disabled',
        flat && 'button__flat',
        className
      )}
    >
      {label || (labelId && <Str id={labelId} />)}
    </button>
  );
};

export default Button;
