import React, { useState } from 'react';
import SplittedLayout from 'components/layouts/SplittedLayout';
import './UsersResearch.scss';
import { useFunctions } from 'reactfire';
import InputSearch from 'components/atoms/InputSearch';
import Loader from 'components/atoms/Loader';
import { withAuth } from 'auth';
import clsx from 'clsx';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import UserInfoDetails from 'components/organisms/UserInfoDetails';
import { Str } from 'i18n';

interface UserInfoSearch {
  name: string;
  uid: string;
  email: string;
  phone: string;
}

const Home: React.FunctionComponent = (): JSX.Element => {
  const query = new URLSearchParams(useLocation().search);
  const queryUid = query.get('uid') || '';
  const { push, replace } = useHistory();
  const { url } = useRouteMatch();

  const [searchParam, setSearchParam] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<UserInfoSearch[]>([]);

  const userSearch = useFunctions().httpsCallable('userSearch');

  const onSearch = async (query: string) => {
    setUsers([]);
    try {
      setIsLoading(true);
      const data: UserInfoSearch[] = (await userSearch(query)).data;
      setIsLoading(false);
      setUsers(data);
    } catch (err) {
      alert(err);
      setIsLoading(false);
    }
  };

  return (
    <SplittedLayout
      left={
        <div className="usersResearch">
          <InputSearch
            hintId="search_by_email_name_uid"
            disableSearch={isLoading}
            value={searchParam}
            onChange={setSearchParam}
            onSearch={onSearch}
          />
          <div className="usersResearch__users">
            {isLoading && <Loader />}
            {!isLoading && (
              <>
                <Str id="search_results" /> {` (${users.length})`}
                {users.map((u: UserInfoSearch) => (
                  <div
                    key={u.uid}
                    className={clsx(
                      'usersResearch__user',
                      queryUid === u.uid && 'usersResearch__user__selected'
                    )}
                    onClick={() => push(`${url}?uid=${u.uid}`)}
                  >
                    <p>
                      <b>{u.name}</b>
                      <br />
                      {u.email && (
                        <>
                          {u.email}
                          <br />
                        </>
                      )}
                      {u.phone && (
                        <>
                          {u.phone}
                          <br />
                        </>
                      )}
                      <small>{u.uid}</small>
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      }
      right={
        queryUid && (
          <UserInfoDetails
            uid={queryUid}
            onInvalidId={() => {
              query.delete('uid');
              replace(`${url}?${query.toString()}`);
            }}
          />
        )
      }
    />
  );
};

export default withAuth(Home);
