import Button from 'components/atoms/Button';
import Counter from 'components/atoms/Counter';
import Input from 'components/atoms/Input';
import Loader from 'components/atoms/Loader';
import Modal from 'components/layouts/Modal';
import firebase from 'firebase';
import { Str } from 'i18n';
import { PushForm } from 'models/Push';
import React, { useState } from 'react';
import './SendPushModal.scss';

interface SendPushModalProps {
  initialValues?: PushForm;
  onDone: (push?: PushForm) => Promise<void>;
}

const isValidUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

const SendPushModal: React.FunctionComponent<SendPushModalProps> = ({
  initialValues,
  onDone,
}): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const [push, setPush] = useState<PushForm>({
    body: initialValues?.body || '',
    open_url: initialValues?.open_url || '',
    title: initialValues?.title || '',
    to: 'broadcast',
    created_at: firebase.firestore.Timestamp.now(),
    updated_at: firebase.firestore.Timestamp.now(),
  });

  const canSend =
    push.title && push.body && (!push.open_url || isValidUrl(push.open_url));

  return (
    <Modal onClose={() => onDone()}>
      {loading ? (
        <Loader size="medium" />
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (window.confirm('vuoi inviare la notifica?')) {
              setLoading(true);
              try {
                await onDone({
                  ...push,
                  open_url: push.open_url
                    ? new URL(push.open_url).toString()
                    : '',
                  created_at: firebase.firestore.Timestamp.now(),
                  updated_at: firebase.firestore.Timestamp.now(),
                });
              } finally {
                setLoading(false);
              }
            }
          }}
          className="transferOwnershipModal"
        >
          <p>
            <b>
              <Str id="new_push" />
            </b>
          </p>
          <Input
            value={push.title}
            onChange={(title) => {
              setPush((prev) => ({ ...prev, title }));
            }}
            hintId="new_push_title"
          />
          <Input
            value={push.body}
            onChange={(body) => {
              setPush((prev) => ({ ...prev, body }));
            }}
            hintId="new_push_body"
            textArea
          />
          <Counter string={push.body} />
          <Input
            value={push.open_url}
            onChange={(open_url) => {
              setPush((prev) => ({ ...prev, open_url }));
            }}
            hintId="new_push_open_url"
          />
          <div className="inputModal__actions">
            <Button labelId="cancel" secondary flat onClick={() => onDone()} />
            <Button disabled={!canSend} labelId="new_push_send" type="submit" />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default SendPushModal;
