import { AuthCheck } from 'reactfire';
import React from 'react';
import useAdmin from 'hooks/useAdmin';

interface FallbackProps {
  fallback?: React.ReactNode;
  admin?: boolean;
}

const AdminComponent: React.FunctionComponent<FallbackProps> = ({
  fallback = <></>,
  children,
  admin,
}): JSX.Element => {
  const isAdmin = useAdmin();
  return admin ? isAdmin ? <>{children}</> : <>{fallback}</> : <>{children}</>;
};

export const UserComponent: React.FunctionComponent<FallbackProps> = (
  props
): JSX.Element => {
  return (
    <AuthCheck fallback={props.fallback}>
      <AdminComponent {...props} />
    </AuthCheck>
  );
};
