import React from 'react';
import clsx from 'clsx';
import './UserImage.scss';
import defaultImage from 'assets/images/sagomacacciatore.png';

interface ButtonProps {
  src: string;
  small?: boolean;
  onClick?: () => void;
  className?: string;
}

const shouldBeReplacedWithDefault = (src: string) => {
  return (
    src.includes('sagomacacciatore') ||
    src.indexOf('file:///var/mobile/') === 0 ||
    src.indexOf('/data/user/') === 0
  );
};

const UserImage: React.FunctionComponent<ButtonProps> = ({
  src,
  small = false,
  onClick,
  className,
}): JSX.Element => {
  const image = src && !shouldBeReplacedWithDefault(src) ? src : defaultImage;
  return (
    <img
      onClick={onClick}
      alt="User profile"
      src={image}
      className={clsx(
        'userImage__image',
        small && 'userImage__image__small',
        className
      )}
    />
  );
};

export default UserImage;
