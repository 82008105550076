import React, { useEffect } from 'react';
import Button from 'components/atoms/Button';
import Modal from 'components/layouts/Modal';
import { Str, useTranslate } from 'i18n';
import Input from 'components/atoms/Input';
import './TransferOwnershipModal.scss';
import { useOwnerTransfer, State } from 'hooks/useOwnerTransfer';
import Loader from 'components/atoms/Loader';

interface TransferOwnershipModalProps {
  deviceId: string;
  onDone: () => void;
}

const InputModal: React.FunctionComponent<TransferOwnershipModalProps> = ({
  deviceId,
  onDone,
}): JSX.Element => {
  const { t } = useTranslate();
  const { request, setRequestAttr, transfer, state } = useOwnerTransfer(
    deviceId
  );

  const canTransfer = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
    request.email
  );

  useEffect(() => {
    if (state === State.SUCCESS) {
      onDone();
      alert(t('request_sent'));
    } else if (state === State.ERROR) {
      alert(t('request_send_error'));
    }
  }, [state, onDone]);

  return (
    <Modal onClose={(state !== State.LOADING && onDone) || undefined}>
      {state === State.LOADING ? (
        <Loader size="medium" />
      ) : (
        <form
          onSubmit={(e) => {
            canTransfer &&
              window.confirm(t('owner_transfer_confirm_message')) &&
              transfer();
            e.preventDefault();
          }}
          className="transferOwnershipModal"
        >
          <p>
            <b>
              <Str id="transfer_owner_request" />
            </b>
          </p>
          <Input
            value={request.email}
            onChange={(email) => setRequestAttr('email', email)}
            hintId="new_owner_email"
          />
          <Input
            value={request.message || ''}
            onChange={(message) => setRequestAttr('message', message)}
            hintId="transfer_reason_message"
            textArea
          />
          <div className="inputModal__actions">
            <Button labelId="cancel" secondary flat onClick={onDone} />
            <Button
              labelId="send_request"
              disabled={!canTransfer}
              type="submit"
            />
          </div>
        </form>
      )}
    </Modal>
  );
};

export default InputModal;
