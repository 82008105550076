import Button from 'components/atoms/Button';
import { useTranslate } from 'i18n';
import Push from 'models/Push';
import React from 'react';
import './PushDetails.scss';

interface PushDetailsProps extends Push {
  onSendAgain: () => void;
}

const PushDetails: React.FunctionComponent<PushDetailsProps> = ({
  title,
  created_at,
  updated_at,
  body,
  message_id,
  open_url,
  onSendAgain,
}): JSX.Element => {
  const { t } = useTranslate();

  return (
    <>
      <div className="pushDetails">
        <h3>{title}</h3>
        <div className="pushDetails__content">
          <span>{body}</span>
          {open_url && (
            <span>
              <a href={open_url} target="_blank" rel="noopener noreferrer">
                {open_url}
              </a>
            </span>
          )}
        </div>
        <div className="pushDetails__info">
          <span>
            {t('created_at', {
              date: created_at.toDate().toLocaleDateString(),
              time: created_at.toDate().toLocaleTimeString(),
              milliseconds: created_at.toDate().getMilliseconds().toString(),
            })}
          </span>
          <span>
            {t('delivered_at', {
              time: updated_at.toDate().toLocaleTimeString(),
              milliseconds: updated_at.toDate().getMilliseconds().toString(),
            })}
          </span>
          <span>{t('message_id', { id: `${message_id}` })}</span>
        </div>
        <span>
          <Button labelId="push_send_again" onClick={onSendAgain} />
        </span>
      </div>
    </>
  );
};

export default PushDetails;
