import clsx from 'clsx';
import { useTranslate } from 'i18n';
import Push from 'models/Push';
import React from 'react';
import './PushListItem.scss';

interface PushListItemProps extends Push {
  selected?: boolean;
  onClick?: () => void;
}

const PushListItem: React.FunctionComponent<PushListItemProps> = ({
  title,
  selected,
  onClick,
  created_at,
  updated_at,
  message_id,
}): JSX.Element => {
  const { t } = useTranslate();

  return (
    <div
      className={clsx('pushListItem', selected && 'pushListItem__selected')}
      onClick={onClick}
    >
      <strong>{title}</strong>
      <br />
      {t('created_at', {
        date: created_at.toDate().toLocaleDateString(),
        time: created_at.toDate().toLocaleTimeString(),
        milliseconds: created_at.toDate().getMilliseconds().toString(),
      })}
      <br />
      {message_id ? (
        <>
          {t('delivered_at', {
            time: updated_at.toDate().toLocaleTimeString(),
            milliseconds: updated_at.toDate().getMilliseconds().toString(),
          })}
          <br />
        </>
      ) : (
        t('delivering')
      )}
    </div>
  );
};

export default PushListItem;
