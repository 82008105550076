import React from 'react';
import translate from './translate';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_LANG } from './locales';

export function useTranslate(): {
  lang: string;
  setLang: React.Dispatch<string>;
  t: (key?: string, values?: Record<string, string>, def?: string) => string;
  langUrl: (path: string) => string;
} {
  const { lang = DEFAULT_LANG } = useParams();
  const { replace, location } = useHistory();
  const query = useLocation().search;

  const setLang = (newLang: string) => {
    void replace(
      `${location.pathname.replace(`/${lang}`, `/${newLang}`)}${query}`,
      location.state
    );
  };

  const t = React.useCallback(
    (key?: string, values?: Record<string, string>, def?: string): string =>
      key ? translate(lang, key, values, def) : '',
    [lang]
  );

  const langUrl = React.useCallback(
    (path: string): string => `/${lang}${path}`,
    [lang]
  );

  return { lang: lang || DEFAULT_LANG, setLang, t, langUrl };
}

export default useTranslate;
