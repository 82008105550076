import Device from 'models/Device';
import UserInfo from 'models/UserInfo';
import {
  useDatabase,
  useDatabaseObjectData,
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import Session from 'models/Session';

interface UserInfoDetails {
  user: UserInfo;
  owneredDevices: Device[];
  connectedDevices: Device[];
  sessions: Session[];
}

const useUserInfo = (uid: string): UserInfoDetails => {
  const database = useDatabase();
  const firestore = useFirestore();

  const userRef = database.ref(`utenteInfo/${uid}`);
  const user: UserInfo = useDatabaseObjectData(userRef);

  const devicesRef = firestore
    .collection('devices')
    .where('users', 'array-contains', uid);
  const devices: Device[] = useFirestoreCollectionData(devicesRef);

  const sessionsRef = firestore
    .collection('users')
    .doc(uid)
    .collection('sessions');
  const sessions: Session[] = useFirestoreCollectionData(sessionsRef);

  return {
    user,
    sessions,
    owneredDevices: devices && devices.filter((d) => d.owner === uid),
    connectedDevices: devices && devices.filter((d) => d.owner !== uid),
  };
};

export default useUserInfo;
