import React from 'react';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import markerIcon from 'assets/icons/marker-red.svg';
import './Map.scss';

const MapBox = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYmVuZWxsaSIsImEiOiJjazJ1ZHM1dzgxM3dhM25udjZkdm9oYXpmIn0.LUBj3l-0RU-a1HNaEgQOSg', // accessToken can also be set per map (accessToken input of mgl-map)
});

interface Marker {
  latitude: number;
  longitude: number;
}

interface MapProps {
  marker?: {
    latitude: number;
    longitude: number;
  };
}

const Map: React.FunctionComponent<MapProps> = ({ marker }): JSX.Element => {
  return (
    <MapBox
      zoom={[14]}
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/mapbox/satellite-v9"
      containerStyle={{
        height: '100%',
        width: '100%',
      }}
      center={marker && [marker.longitude, marker.latitude]}
    >
      {marker && (
        <Marker
          coordinates={[marker.longitude, marker.latitude]}
          anchor="bottom"
        >
          <img className="map__marker" src={markerIcon} alt="Last position" />
        </Marker>
      )}
    </MapBox>
  );
};

export default Map;
