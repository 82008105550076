import { useFunctions } from 'reactfire';
import { useCallback, useState } from 'react';
import { OwnerTransferRequest } from 'models/OwnerTransferRequest';

export enum State {
  LOADING,
  SUCCESS,
  ERROR,
}

interface OwnerTransfer {
  request: OwnerTransferRequest;
  setRequestAttr: (attr: keyof OwnerTransferRequest, value: string) => void;
  transfer: () => void;
  state?: State;
}

export const useOwnerTransfer = (deviceId: string): OwnerTransfer => {
  const functions = useFunctions();
  const [state, setState] = useState<State | undefined>();
  const [request, setRequest] = useState<OwnerTransferRequest>({
    email: '',
    deviceId,
  });

  const transfer = useCallback(async () => {
    if (state === State.LOADING) return;
    const ownerTransfer = functions.httpsCallable('ownerTransferRequest');
    setState(State.LOADING);
    try {
      await ownerTransfer(request);
      setState(State.SUCCESS);
    } catch (e) {
      console.error(e);
      setState(State.ERROR);
    }
  }, [functions, request, state, setState]);

  const setRequestAttr = useCallback(
    (attr: keyof OwnerTransferRequest, value: string) => {
      setRequest({
        ...request,
        [attr]: value,
      });
    },
    [setRequest, request]
  );

  return {
    request,
    setRequestAttr,
    transfer,
    state,
  };
};
