import React from 'react';
import './AppLayout.scss';
import Header, { HeaderProps } from 'components/molecules/Header';

const AppLayout: React.FunctionComponent<HeaderProps> = ({
  children,
  ...props
}): JSX.Element => {
  return (
    <div className="appLayout">
      <Header {...props} />
      <div className="appLayout__content">{children}</div>
    </div>
  );
};

export default AppLayout;
