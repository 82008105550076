import React from 'react';
import Button from 'components/atoms/Button';
import { LANGS, useTranslate } from 'i18n';

const LangSelection: React.FunctionComponent = (): JSX.Element => {
  const { setLang, lang: current } = useTranslate();
  return (
    <div>
      {LANGS.map((lang) => (
        <Button
          label={lang.toUpperCase()}
          onClick={() => setLang(lang)}
          key={lang}
          flat
          secondary={current !== lang}
        />
      ))}
    </div>
  );
};

export default LangSelection;
