import React, { useState, useEffect } from 'react';
import {
  useUser,
  useDatabase,
  useDatabaseObjectData,
  useAuth,
} from 'reactfire';
import { User } from 'firebase';
import UserInfo from 'models/UserInfo';
import './UserProfile.scss';
import Modal from 'components/layouts/Modal';
import Button from 'components/atoms/Button';
import { UserComponent } from 'auth';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'i18n';
import LangSelection from 'components/molecules/LangSelection';
import UserImage from 'components/atoms/UserImage';

const UserProfile: React.FunctionComponent = (): JSX.Element => {
  const auth = useAuth();
  const { langUrl } = useTranslate();
  const { push, location } = useHistory();
  const user: User = useUser();
  const userRef = useDatabase().ref(`utenteInfo/${user.uid}`);
  const userInfo: UserInfo = useDatabaseObjectData(userRef);
  const [visible, setVisible] = useState(false);

  useEffect(() => setVisible(false), [location]);

  return (
    <>
      <UserImage
        className="userProfile__thumb"
        src={userInfo.immagine}
        small
        onClick={() => setVisible(!visible)}
      />
      {visible && (
        <Modal onClose={() => setVisible(false)}>
          <div className="userProfile__list">
            <UserImage src={userInfo.immagine} />
            <h2>{userInfo.nome}</h2>
            {process.env.NODE_ENV === 'development' && (
              <small>{user.uid}</small>
            )}
            <LangSelection />
            <Button
              flat
              full
              secondary
              labelId="your_devices"
              onClick={() => push(langUrl('/home'))}
            />
            <UserComponent admin>
              <Button
                flat
                full
                secondary
                labelId="push_notifications"
                onClick={() => push(langUrl('/push-notification'))}
              />
            </UserComponent>
            <UserComponent admin>
              <Button
                flat
                full
                secondary
                labelId="users_research"
                onClick={() => push(langUrl('/users-research'))}
              />
            </UserComponent>
            <br />
            <Button
              labelId="logout"
              onClick={() => {
                auth.signOut();
                setTimeout(() => window.location.reload(false), 0); //fix firestore error
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default UserProfile;
