import Device, { UserPosition } from 'models/Device';
import UserInfo from 'models/UserInfo';
import {
  useUser,
  useDatabase,
  useDatabaseObjectData,
  useFirestore,
  useFirestoreDocData,
} from 'reactfire';
import { User } from 'firebase';
import { useState, useCallback } from 'react';
import { useTranslate } from 'i18n';

interface DeviceDetails {
  device: Device;
  lockToggle: () => void;
  isCollar: boolean;
  isMine: boolean;
  position?: UserPosition;
  owner: UserInfo;
  //users?: UserInfo[];
  lockLoading: boolean;
  isVisible: boolean;
}

const useDevice = (deviceId: string): DeviceDetails => {
  const { t } = useTranslate();
  const database = useDatabase();
  const firestore = useFirestore();
  const devRef = firestore.collection('devices').doc(deviceId);
  const d: Device = useFirestoreDocData(devRef);
  const user: User = useUser();
  const isMine = d.owner === user.uid;

  const position =
    d.lastPositions &&
    d.lastPositions.length &&
    d.lastPositions
      .filter((p) => p.uid === user.uid)
      .sort((a, b) => b.timestamp - a.timestamp)[0];

  const ownerRef = database.ref(`utenteInfo/${d.owner}`);
  const owner: UserInfo = useDatabaseObjectData(ownerRef);
  const [lockLoading, setLockLoading] = useState(false);

  const lockToggle = useCallback(() => {
    if (window.confirm(t(d.locked ? 'unlock_message' : 'lock_message'))) {
      setLockLoading(true);
      devRef
        .update({ ...d, locked: !d.locked })
        .catch((e) => console.error(e))
        .finally(() => setLockLoading(false));
    }
  }, [d, devRef, t]);

  /*const [users, setUsers] = useState<UserInfo[]>();

  const fetchUsers = useCallback(
    async (users: string[]) => {
      const result: UserInfo[] = [];
      for (const i in users) {
        const snapshot = await database
          .ref(`utenteInfo/${users[i]}`)
          .once('value');
        result.push(snapshot.val());
      }
      setUsers(result);
    },
    [database]
  );

  useEffect(() => {
    if (isMine && d.users) {
      fetchUsers(d.users.filter((uid) => uid !== d.owner));
    } else {
      setUsers(undefined);
    }
  }, [d.users, d.owner, isMine, fetchUsers]);*/

  return {
    isVisible: !!d.deviceId && (isMine || d.users.indexOf(user.uid) !== -1),
    device: d,
    lockToggle,
    position: position || undefined,
    isCollar: `${d.deviceId}`.length < 5,
    owner,
    //users,
    lockLoading,
    isMine,
  };
};

export default useDevice;
