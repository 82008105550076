import React, { useState } from 'react';
import './Login.scss';
import SocialButton from 'components/atoms/SocialButton';
import google from 'assets/icons/logo-google-white.svg';
import facebook from 'assets/icons/logo-facebook-white.svg';
import apple from 'assets/icons/logo-apple-white.svg';
import { Str } from 'i18n';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { useAuth } from 'reactfire';
import clsx from 'clsx';
import Loader from 'components/atoms/Loader';
import { withoutAuth } from 'auth';

const Login: React.FunctionComponent = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const FACEBOOK_P = new useAuth.FacebookAuthProvider();
  const GOOGLE_P = new useAuth.GoogleAuthProvider();
  const APPLE_P = new useAuth.OAuthProvider('apple.com');
  APPLE_P.addScope('email');
  APPLE_P.addScope('name');

  const sign = (signFn: () => Promise<unknown>) => async () => {
    setLoading(true);
    setError(undefined);
    try {
      await signFn();
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(e);
    }
  };

  const signIn = React.useCallback(
    sign(() => auth.signInWithEmailAndPassword(email, pwd)),
    [setLoading, setError, auth, email, pwd]
  );

  const socialSignIn = React.useCallback(
    (provider) => sign(() => auth.signInWithPopup(provider))(),
    [setLoading, setError, auth]
  );

  return (
    <div className="login">
      <div
        className={clsx('login__content', loading && 'login__content__loading')}
      >
        <p>
          <b>
            <Str id="login" />
          </b>
        </p>
        <SocialButton
          full
          icon={google}
          color="#DA3D2A"
          label="Google"
          onClick={() => socialSignIn(GOOGLE_P)}
        />
        <SocialButton
          full
          icon={facebook}
          color="#4267B2"
          label="Facebook"
          onClick={() => socialSignIn(FACEBOOK_P)}
        />
        <SocialButton
          full
          icon={apple}
          color="#313131"
          label="Apple"
          onClick={() => socialSignIn(APPLE_P)}
        />
        <p>
          <Str id="or_use_your_email" />
        </p>
        <form
          onSubmit={(e) => {
            signIn();
            e.preventDefault();
          }}
        >
          <Input
            value={email}
            type="email"
            hintId="email"
            onChange={setEmail}
          />
          <Input
            value={pwd}
            type="password"
            hintId="password"
            onChange={setPwd}
          />
          {error && (
            <p>
              <Str id={error?.['code'] || ''} def={error?.['message']} />
            </p>
          )}
          <Button labelId="login" disabled={!email || !pwd} type="submit" />
        </form>
      </div>
      {loading && (
        <div className="loaderContainer">
          <Loader size="medium" />
        </div>
      )}
    </div>
  );
};

export default withoutAuth(Login);
