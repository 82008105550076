export enum SessionState {
  NEW = 1,
  OPEN = 2,
  PAUSED = 3,
  CLOSED = 4,
  ARCHIVED = 5,
}

interface Session {
  sessionId: string;
  name: string;
  createdTime: string;
  modifiedTime: string;
  state: SessionState;
}

export default Session;
