import React, { useState } from 'react';
import { useTranslate } from 'i18n';
import './InputSearch.scss';
import Button from '../Button';

interface InputProps {
  hintId?: string;
  value: string;
  disableSearch?: boolean;
  onChange: (value: string) => void;
  onSearch: (value: string) => void;
  type?: string;
}

const InputSearch: React.FunctionComponent<InputProps> = ({
  hintId = '',
  value,
  disableSearch = false,
  onChange,
  onSearch,
  type = 'text',
}): JSX.Element => {
  const [currentValue, setCurrentValue] = useState(value);
  const { t } = useTranslate();

  const props = {
    type,
    value,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setCurrentValue(val);
      onChange(val);
    },
    placeholder: t(hintId),
  };

  const search = (value: string) => {
    !disableSearch && onSearch(value);
  };

  return (
    <div className="inputSearch">
      <input
        {...props}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            search(currentValue);
          }
        }}
      />
      <Button
        labelId="search"
        flat
        onClick={() => search(currentValue)}
        disabled={disableSearch}
      />
    </div>
  );
};

export default InputSearch;
