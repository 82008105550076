import React, { useState } from 'react';
import './UserInfoDetails.scss';
import useUserInfo from 'hooks/useUserInfo';
import UserImage from 'components/atoms/UserImage';
import { Str } from 'i18n';
import DeviceSmallItem from 'components/molecules/DeviceSmallItem';
import { SessionState } from 'models/Session';
import Button from 'components/atoms/Button';
import CopySessionModal from '../CopySessionModal';

interface UserInfoDetailsProps {
  uid: string;
  onInvalidId: () => void;
}

const UserInfoDetails: React.FunctionComponent<UserInfoDetailsProps> = ({
  uid,
  onInvalidId,
}): JSX.Element => {
  const { user, connectedDevices, owneredDevices, sessions } = useUserInfo(uid);
  const [copySessionId, setCopySessionId] = useState<string | undefined>();

  if (!user.uid) {
    onInvalidId();
    return <noscript />;
  }

  return (
    <>
      <div className="userInfoDetails">
        <div className="userInfoDetails__centeredList">
          <UserImage src={user.immagine} className="userInfoDetails__image" />
          <h2>{user.nome}</h2>
          <b>{user.email}</b>
          {user.telefono}
          <br />
          {user.uid}
        </div>
        <b>
          <Str id="ownered_devices" /> {` (${owneredDevices.length})`}
        </b>
        <div className="userInfoDetails__horizontal">
          {owneredDevices.map((d) => (
            <DeviceSmallItem key={d.deviceId} {...d} />
          ))}
        </div>
        <b>
          <Str id="connected_devices" /> {` (${connectedDevices.length})`}
        </b>
        <div className="userInfoDetails__horizontal">
          {connectedDevices.map((d) => (
            <DeviceSmallItem key={d.deviceId} {...d} />
          ))}
        </div>
        <b>
          <Str id="sessions" /> {` (${sessions.length})`}
        </b>
        <div>
          {sessions.map((s) => (
            <p key={s.sessionId} className="userInfoDetails__session">
              <Button
                className="userInfoDetails__session__copy"
                labelId="copy"
                onClick={() => setCopySessionId(s.sessionId)}
                flat
              />
              {s.name}
              {'   '}
              <span className="userInfoDetails__light">
                {s.sessionId}
                {s.state === SessionState.ARCHIVED ? (
                  <>
                    {' '}
                    <Str id="archived" />)
                  </>
                ) : (
                  ''
                )}
              </span>
            </p>
          ))}
        </div>
      </div>
      {copySessionId && (
        <CopySessionModal
          sessionId={copySessionId}
          uid={uid}
          onDone={() => setCopySessionId(undefined)}
        />
      )}
    </>
  );
};

export default UserInfoDetails;
