import React from 'react';
import SplittedLayout from 'components/layouts/SplittedLayout';
import { User } from 'firebase/app';
import {
  useUser,
  useDatabase,
  useAuth,
  useDatabaseObjectData,
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';
import UserDetails from 'components/molecules/UserDetails';
import { Str } from 'i18n';
import DeviceListItem from 'components/molecules/DeviceListItem';
import Device from 'models/Device';
import UserInfo from 'models/UserInfo';
import DeviceDetails from 'components/organisms/DeviceDetails';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { withAuth } from 'auth';

const Home: React.FunctionComponent = (): JSX.Element => {
  const query = new URLSearchParams(useLocation().search);
  const queryDeviceId = query.get('deviceId');
  const { push, replace } = useHistory();
  const { url } = useRouteMatch();

  const user: User = useUser();
  const auth = useAuth();
  const devicesRef = useFirestore()
    .collection(`devices`)
    .where('users', 'array-contains', user.uid);
  const devices: Device[] = useFirestoreCollectionData(devicesRef);
  const userRef = useDatabase().ref(`utenteInfo/${user.uid}`);
  const userInfo: UserInfo = useDatabaseObjectData(userRef);

  const userDevices = devices.filter((d) => d.owner === user.uid);
  const lendingDevices = devices.filter((d) => d.owner !== user.uid);

  const parseDevices = (devices: Device[]) =>
    devices.map((d, i) => (
      <DeviceListItem
        {...d}
        key={i}
        onClick={() => push(`${url}?deviceId=${d.deviceId}`)}
        selected={d.deviceId.toString() === queryDeviceId}
      />
    ));

  return (
    <SplittedLayout
      left={
        <>
          {userInfo && (
            <UserDetails
              {...userInfo}
              onLogout={() => {
                auth.signOut();
                setTimeout(() => window.location.reload(false), 0); //fix firestore error
              }}
            />
          )}
          <p>
            <Str id="your_devices" />
          </p>
          {parseDevices(userDevices)}
          <p>
            <Str id="connected_devices" />
          </p>
          {parseDevices(lendingDevices)}
        </>
      }
      right={
        queryDeviceId && (
          <DeviceDetails
            deviceId={queryDeviceId}
            onInvalidId={() => {
              query.delete('deviceId');
              replace(`${url}?${query.toString()}`);
            }}
          />
        )
      }
    />
  );
};

export default withAuth(Home);
