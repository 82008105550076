import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LANGS, DEFAULT_LANG } from '../locales';

const LangRoute: React.FunctionComponent = ({ children }): JSX.Element => (
  <Switch>
    <Route
      path="/:lang"
      render={({ match }) => (
        <>
          {LANGS.includes(match?.params.lang) ? (
            children
          ) : (
            <Redirect to={`/${DEFAULT_LANG}`} />
          )}
        </>
      )}
    />
    <Route exact path="/">
      <Redirect to={`/${DEFAULT_LANG}`} />
    </Route>
  </Switch>
);

export default LangRoute;
