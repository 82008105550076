import React from 'react';
import { AuthCheck } from 'reactfire';
import { Redirect, useLocation } from 'react-router-dom';
import useAdmin from 'hooks/useAdmin';
import { useTranslate } from 'i18n';

interface Options {
  redirect: boolean;
}

export function withAuth<P>(
  WrappedComponent: React.ComponentType<P>,
  options: Options = { redirect: true }
): React.FunctionComponent<P> {
  const AuthComponent: React.FunctionComponent<P> = (props: P): JSX.Element => (
    <AuthCheck fallback={<RedirectFallback options={options} path="/login" />}>
      <WrappedComponent {...props} />
    </AuthCheck>
  );
  return AuthComponent;
}

export function withoutAuth<P>(
  WrappedComponent: React.ComponentType<P>,
  options?: Options
): React.FunctionComponent<P> {
  const AnauthComponent: React.FunctionComponent<P> = (
    props: P
  ): JSX.Element => (
    <AuthCheck fallback={<WrappedComponent {...props} />}>
      <RedirectFallback options={options} path="/home" />
    </AuthCheck>
  );
  return AnauthComponent;
}

export function withAdmin<P>(
  WrappedComponent: React.ComponentType<P>,
  options?: Options
): React.FunctionComponent<P> {
  const AdminComponent: React.FunctionComponent<P> = (
    props: P
  ): JSX.Element => {
    const isAdmin = useAdmin();
    return isAdmin ? (
      <WrappedComponent {...props} />
    ) : (
      <RedirectFallback options={options} path="/home" />
    );
  };
  return withAuth(AdminComponent);
}

interface RedirectFallbackProps {
  options?: Options;
  path: string;
}
const RedirectFallback: React.FunctionComponent<RedirectFallbackProps> = ({
  options = { redirect: true },
  path,
}): JSX.Element => {
  const { langUrl } = useTranslate();
  const { search } = useLocation();
  return options.redirect ? (
    <Redirect to={langUrl(`${path}${search}`)} />
  ) : (
    <></>
  );
};
