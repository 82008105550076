import React from 'react';
import './SplittedLayout.scss';

interface SplittedLayoutProps {
  left: React.ReactNode;
  right: React.ReactNode;
}
const SplittedLayout: React.FunctionComponent<SplittedLayoutProps> = ({
  left,
  right,
}): JSX.Element => {
  return (
    <div className="splittedLayout">
      <div className="splittedLayout__left">{left}</div>
      <div className="splittedLayout__right">{right}</div>
    </div>
  );
};

export default SplittedLayout;
