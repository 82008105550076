import { useFirestore, useFirestoreDocData, useUser } from 'reactfire';
import UserRole from 'models/UserRole';
import { User } from 'firebase';

const useAdmin = (): boolean => {
  const user: User = useUser();
  const roleRef = useFirestore().collection('roles').doc(user.uid);
  const role: UserRole = useFirestoreDocData(roleRef);
  return role && role.admin;
};

export default useAdmin;
